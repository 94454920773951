import React, { useEffect, useState } from "react";
import { Box, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation, useHistory } from "react-router-dom";
import BodyData from "../BodyData";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SliderCard from "../Cards/SliderCard";
import { getCroppedPicture, getFullPicture } from "../../modules/S3";
import { makeStyles } from "@material-ui/core/styles";
import { properties } from "../../services/feathers";
import clsx from "clsx";
import Loader from "react-loader-spinner";
import numeral from "numeral";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";

function Index(props) {
  let { search, path } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const property = params.get("property");
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (property) {
      setLoading(true);
      setSelected(null);
      properties
        .find({ query: { id: property } })
        .then(res => {
          if (res.data.length > 0) {
            setSelected(res.data[0]);
          } else {
            setSelected(null);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      if (selected) {
        ReactGA.pageview(
          window.location.pathname + window.location.search,
          `Martha Zuccardi - 
                ${(selected || {}).selling ? "Venta" : "Arriendo"} ${
            (selected.type || {}).name
          }  en ${(selected.city || {}).name} estrato ${selected.stratum}  de ${
            selected.rooms
          }  cuartos y ${numeral(selected.area_m2).format("0")} m2 - código ${
            selected.id
          }`,
          `Martha Zuccardi - 
                ${(selected || {}).selling ? "Venta" : "Arriendo"} ${
            (selected.type || {}).name
          }  en ${(selected.city || {}).name} Estrato ${selected.stratum}  de ${
            selected.rooms
          }  Cuartos y ${numeral(selected.area_m2).format("0")} m2 - Código ${
            selected.id
          }`
        );
      }
    }
  }, [property]);

  return (
    <div>
      {property ? (
        <React.Fragment>
          {selected && (
            <Helmet>
              <title>
                Martha Zuccardi -
                {`${(selected || {}).selling ? "Venta" : "Arriendo"} ${
                  (selected.type || {}).name
                }  en ${(selected.city || {}).name} Estrato ${
                  selected.stratum
                }  de ${selected.rooms}  Cuartos y ${numeral(
                  selected.area_m2
                ).format("0")} m2 - Código ${selected.id}`}
              </title>
              <meta
                name="description"
                content={`${(selected || {}).selling ? "Venta" : "Arriendo"} ${
                  (selected.type || {}).name
                }  exclusivo  ubicado en la ciudad de ${
                  (selected.city || {}).name
                } Cuenta con ${selected.rooms} cuartos y ${
                  selected.area_m2
                } m2 . Ingresa para conocer el precio`}
              />

              <meta
                property="og:title"
                content={`Martha Zuccardi - 
                ${(selected || {}).selling ? "Venta" : "Arriendo"} ${
                  (selected.type || {}).name
                }  en ${(selected.city || {}).name} Estrato ${
                  selected.stratum
                }  de ${selected.rooms}  Cuartos y ${numeral(
                  selected.area_m2
                ).format("0")} m2 - Código ${selected.id}`}
              />
              <meta
                property="og:description"
                content={`${(selected || {}).selling ? "Venta" : "Arriendo"} ${
                  (selected.type || {}).name
                }  exclusivo  ubicado en la ciudad de ${
                  (selected.city || {}).name
                } Cuenta con ${selected.rooms} cuartos y ${
                  selected.area_m2
                } m2 . Ingresa para conocer el precio`}
              />
              <meta
                property="og:image"
                itemProp="image"
                content={getCroppedPicture(
                  ((selected.media || [])[0] || {}).path,
                  "contain",
                  { width: 300, height: 300 }
                )}
              />
            </Helmet>
          )}
          <Modal
            open={true}
            onBackdropClick={() => history.push(path)}
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              display: "flex",
              borderWidth: 0
            }}
          >
            <div className={classes.mainSection}>
              <Box
                style={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  zIndex: 999,
                  borderRadius: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(255,255,255,.7)",
                  height: "30px",
                  width: "30px"
                }}
                onClick={() => history.push(path)}
              >
                <CloseIcon />
              </Box>
              {selected ? (
                <div>
                  <div>
                    <Carousel
                      emulateTouch
                      infiniteLoop
                      showArrows={true}
                      dynamicHeight
                      className={classes.carousel}
                      showThumbs={false}
                    >
                      {(selected.media || []).map(el => (
                        <SliderCard
                          type={el.type}
                          source={getFullPicture((el || {}).path, "contain")}
                        />
                      ))}
                    </Carousel>
                  </div>

                  <Box className={classes.hSpace}>
                    <Box>
                      <Typography className={classes.text}>
                        {`${(selected.type || {}).name} - ${numeral(
                          selected.area_m2
                        ).format("0")}m2 - Estrato ${selected.stratum}`}
                      </Typography>
                      <Typography className={classes.text}>{`${(
                        selected.city || {}
                      ).name || ""}, ${(selected.neighborhood || {}).name ||
                        ""}`}</Typography>
                    </Box>
                    <Box className={classes.rooms}>
                      <Box className={classes.roomSection}>
                        <Typography
                          className={clsx(classes.text)}
                        >{`${selected.rooms}`}</Typography>
                        <Typography className={clsx(classes.text)}>
                          Cuartos
                        </Typography>
                      </Box>
                      <div className={classes.separator} />
                      <Box className={classes.roomSection}>
                        <Typography className={clsx(classes.text)}>
                          {`${selected.bathrooms}`}
                        </Typography>
                        <Typography className={clsx(classes.text)}>
                          Baños
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <BodyData selected={selected} />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {loading ? (
                    <Loader
                      type="Puff"
                      color="#9FD1C7"
                      height={100}
                      width={100}
                    />
                  ) : (
                    <Typography>
                      Lo sentimos, esta propiedad no se encuentra disponible
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </Modal>
        </React.Fragment>
      ) : (
        <div className={classes.whatsAppBottom}></div>
      )}
      <a
        href={
          property
            ? `https://api.whatsapp.com/send?phone=573106302886&text=Hola, quiero conocer más de esta propiedad: https://marthazuccardi.com/?property=${property}`
            : `https://api.whatsapp.com/send?phone=573106302886&text=Hola, estoy interesado en conocer propiedades.`
        }
        className={classes.float}
        target="_blank"
      >
        <img
          src="https://lalalance2.s3.amazonaws.com/whatsapp-brands.png"
          className={classes.myFloat}
        />
      </a>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  carousel: {},
  informationHr: {
    backgroundColor: theme.palette.primary.main
  },
  mainSection: {
    display: "flex",
    height: "100%",
    backgroundColor: "white",
    width: "100%",
    overflowY: "scroll",
    borderRadius: "5px",
    boxShadow: "0px 0px 115px -32px rgba(0,0,0,1)",
    overflow: "hidden"
  },
  hSpace: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    padding: "5px 10px"
  },
  text: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  rooms: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  roomSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  separator: {
    width: "1.5px",
    height: "50%",
    backgroundColor: theme.palette.secondary.main,
    margin: "0 5px 0 5px"
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  lowText: {
    color: theme.palette.text.primary
  },
  whatsAppBottom: {
    padding: "20px 0",
    backgroundColor: "#FAFAFA",
    justifyContent: "space-around",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none"
  },
  float: {
    position: "fixed",
    width: "50px",
    height: "50px",
    bottom: "40px",
    right: "40px",
    backgroundColor: "#25d366",
    color: "#FFF",
    borderRadius: "50px",
    textAlign: "center",
    boxShadow: "2px 2px 3px #999",
    zIndex: 999999
  },
  myFloat: {
    marginTop: "10px",
    width: "30px",
    height: "auto"
  },
  "@media (min-width: 768px)": {
    mainSection: {
      display: "flex",
      height: "90%",
      width: "500px",
      backgroundColor: "white",
      overflowY: "scroll"
    },
    whatsAppBottom: {
      display: "none"
    }
  }
}));

export default Index;

import { connectCurrentRefinements } from "react-instantsearch-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const CurrentRefinements = ({ items, refine, createURL }) => {
  const classes = useStyles();
  return (
    <ul className={classes.container}>
      {items.map(item => (
        <li key={item.label} className={classes.item}>
          {item.items ? (
            <React.Fragment>
              {item.label}
              <ul className={classes.amenitiesList}>
                {item.items.map(nested => (
                  <div className={classes.item}>
                    <a
                      className={classes.a}
                      href={createURL(nested.value)}
                      onClick={event => {
                        event.preventDefault();
                        refine(nested.value);
                      }}
                    >
                      {nested.label}
                    </a>
                  </div>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            <a
              className={classes.a}
              href={createURL(item.value)}
              onClick={event => {
                event.preventDefault();
                refine(item.value);
              }}
            >
              {item.label}
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};

const useStyles = makeStyles(theme => ({
  amenitiesList: {
    display: "flex",
    listStyle: "none",
    overflowX: "show"
  },
  container: {
    display: "flex",
    listStyle: "none",
    alignItems: "center",
    maxHeight: "30px",
    whiteSpace: "noWrap"
  },
  item: {
    display: "flex",
    listStyle: "none",
    alignSelf: "center",
    padding: "0 10px",
    border: "1px solid #d8d8d8",
    marginRight: "7px"
  },
  li: {
    padding: "3px",
    color: "#bdb7b7",
    textTransform: "capitalize",
    textDecoration: "none",
    border: "1px solid #d8d8d8",
    maxHeight: "20px",
    width: "100%"
  },
  a: {
    width: "100%",
    color: "#bdb7b7",
    textTransform: "capitalize",
    textDecoration: "none"
  }
}));

export default connectCurrentRefinements(CurrentRefinements);

import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connectRange } from "react-instantsearch-dom";
import numeral from "numeral";

import Slider from "@material-ui/core/Slider";

const AirbnbSlider = withStyles({
  root: {
    color: "primary",
    padding: "0px 0px"
  }
})(Slider);

function Index(props) {
  const classes = useStyles();
  const {
    currentRefinement,
    min,
    max,
    refine,
    prefix,
    postfix,
    cash,
    step
  } = props;
  return (
    <div>
      <Box>
        <AirbnbSlider
          value={[currentRefinement.min, currentRefinement.max]}
          max={max}
          min={min}
          step={step || 1}
          onChange={(e, value) => {
            refine({ min: value[0], max: value[1] });
          }}
          valueLabelDisplay="off"
          aria-labelledby="range-slider"
        />
        <Box className={classes.sliderCap}>
          <Typography className={classes.text}>
            {prefix}
            {cash
              ? numeral(currentRefinement.min).format("0,000")
              : currentRefinement.min}
            {postfix}
          </Typography>
          <Typography className={classes.text}>
            {prefix}
            {cash
              ? numeral(currentRefinement.max).format("0,000")
              : currentRefinement.max}
            {postfix}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column"
  },
  text: {
    color: theme.palette.gray.text,
    margin: "10px 0 5px 0"
  },
  sliderCap: {
    display: "flex",
    justifyContent: "space-between"
  }
}));
export default connectRange(Index);

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SellingFilter from "./SellingFilter";
import TypeFilter from "./TypeFilter";
import RoomsFilter from "./RoomsFilter";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import SliderFilters from "./SliderFilters";
import SelectFilters from "./SelectFilters";
import Fade from "@material-ui/core/Fade";
import ClearFilters from "../Algolia/ClearFilters";
import Results from "../Algolia/Results";
import clsx from "clsx";
import ExtraFilter from "./ExtraFilter";
import _ from "lodash";

function AllFilters(props) {
  const { selected, open, closeMenu } = props;
  const classes = useStyles();
  return (
    <Fade direction="column" in={open}>
      <Grid container className={classes.container}>
        <Grid item className={classes.filtersContainer}>
          <div style={{ display: !["all"].includes(selected) && "none" }}>
            <SelectFilters />
          </div>
          <div
            style={{
              display: !["all", "selling_renting"].includes(selected) && "none",
              marginTop: "20px"
            }}
            className={clsx(classes.sectionShadow, classes.section)}
          >
            <SellingFilter />
          </div>

          <div
            style={{
              display: !["all", "rooms"].includes(selected) && "none"
            }}
            className={clsx(classes.section)}
          >
            <RoomsFilter />
          </div>

          <div
            style={{ display: !["all"].includes(selected) && "none" }}
            className={clsx(classes.section)}
          >
            <SliderFilters />
          </div>

          <div
            style={{
              display: !["all", "type"].includes(selected) && "none"
            }}
            className={clsx(classes.section, classes.sectionShadow)}
          >
            <TypeFilter attribute="type.name" searchable label={"Tipo"} />
          </div>

          <div
            style={{ display: !["all"].includes(selected) && "none" }}
            className={clsx(classes.section)}
          >
            <ExtraFilter />
          </div>

          <div
            style={{ display: !["all"].includes(selected) && "none" }}
            className={clsx(classes.section, classes.sectionShadow)}
          >
            <TypeFilter
              attribute="amenities.name"
              label={"Comodidades"}
              searchable
              transformItems={items => _.sortBy(items, ["label"])}
            />
          </div>
        </Grid>
        <Grid item container className={classes.footer}>
          <Grid item xs={6}>
            <ClearFilters clearsQuery closeMenu={closeMenu} />
          </Grid>
          <Grid item xs={6} className={classes.footerTwo}>
            <Box clone className={classes.button}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  closeMenu();
                }}
              >
                <Results />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    maxHeight: "500px",
    position: "absolute",
    listStyleImage: `url('../../assets/images/list.png')`,
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    height: "40px",
    width: "100%",
    display: "flex",
    position: "absolute",
    bottom: 0,
    alignItems: "center"
  },
  button: {
    textTransform: "none",
    color: theme.palette.primary.main,
    borderRadius: 0,
    alignSelf: "center",
    height: "30px",
    marginRight: "5px"
  },
  footerTwo: {
    display: "flex",
    justifyContent: "flex-end"
  },
  resetText: {
    display: "inline-block",
    color: theme.palette.secondary.main,
    marginLeft: "5px",
    cursor: "pointer"
  },
  filtersContainer: {
    overflow: "scroll",
    overflowX: "hidden",
    paddingBottom: "50px",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  sectionShadow: {
    boxShadow: "0px 10px 16px 0px rgba(0,0,0,0.16)"
  },
  section: {
    marginBottom: "20px"
  }
}));

export default AllFilters;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function Index(props) {
  return (
    <div onClick={props.onClick}>
      <img
        src={props.source}
        alt={""}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  carousel: {
    height: "500px"
  }
}));

export default Index;

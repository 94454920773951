import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckButtonGroup from "../Radio/CheckButtonGroup";
import SelectableCheckButton from "../Radio/SelectableCheckButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column"
  },
  text: {
    color: theme.palette.gray.text,
    margin: "10px 0 5px 0"
  }
}));

export default function RoomsFilter() {
  const rooms = [
    ...[...Array(4).keys()].map(el => ({
      label: el + 1,
      value: el + 1,
      start: el + 1,
      id: el + 1
    })),
    { label: "5+", value: 5, id: 5, start: 5 }
  ];

  const parkingLot = [
    ...[...Array(3).keys()].map(el => ({
      label: el + 1,
      value: el + 1,
      start: el + 1,
      id: el + 1
    })),
    { label: "4+", value: 4, id: 4, start: 4 }
  ];

  const classes = useStyles();
  return (
    <Fade in={true}>
      <Grid container className={classes.container}>
        <Typography className={classes.text}>Cuartos</Typography>
        <CheckButtonGroup
          attribute="rooms"
          Radio={<SelectableCheckButton />}
          items={rooms}
        />
        <Typography className={classes.text}>Baños</Typography>
        <CheckButtonGroup
          attribute="bathrooms"
          Radio={<SelectableCheckButton />}
          items={rooms}
        />
        <Typography className={classes.text}>Parquedaderos</Typography>
        <CheckButtonGroup
          attribute="parking_slots"
          Radio={<SelectableCheckButton />}
          items={parkingLot}
        />
      </Grid>
    </Fade>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import RangeSlider from "../Algolia/Slider";

function SliderFilters(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>Area m2:</Typography>
      <RangeSlider min={0} max={1500} attribute={"area_m2"} postfix={"m2"} />
      <Typography className={classes.text}>Valor venta</Typography>
      <RangeSlider
        cash
        min={0}
        max={5000000000}
        attribute={"sale_price"}
        prefix={"$"}
        step={1000000}
      />

      <Typography className={classes.text}>Valor arriendo</Typography>
      <RangeSlider
        cash
        min={0}
        max={50000000}
        attribute={"rent_price"}
        prefix={"$"}
        step={100000}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    flexDirection: "column"
  },
  text: {
    color: theme.palette.gray.text,
    margin: "10px 0 5px 0"
  },
  sliderCap: {
    display: "flex",
    justifyContent: "space-between"
  }
}));
export default SliderFilters;

import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import Caviar from "../src/assets/Fonts/proximanova-regular-webfont.woff";
import CaviarBold from "../src/assets/Fonts/proxima_nova_bold-webfont.woff";

// A custom theme for this app

const raleway = {
  fontFamily: "Caviar",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Caviar'),
    url(${Caviar}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#9FD1C7"
    },
    secondary: {
      main: "#FFFFFF",
      opacity: "rgba(255,255,255,.5)"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    },
    gray: {
      border: "#DFDEDE",
      text: "#5B5B5B"
    }
  },
  typography: {
    fontFamily: ["CaviarDreams"].join(",")
  }
});

export default theme;

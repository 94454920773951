import { GET_FILTERS, RESET_FILTERS, SET_FILTERS } from "../types";

const INITIAL_STATE = {
  selling: true,
  renting: true,
  type: new Map(),
  rooms: new Map(),
  bathrooms: new Map(),
  city: "",
  neighborhood: "",
  parkingLot: new Map(),
  area: [110, 350],
  selling_price: [500000000, 1200000000],
  rent_price: [1000000, 3000000],
  amenities: new Map()
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, ...action.payload };
    case GET_FILTERS:
      return state;
    case RESET_FILTERS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

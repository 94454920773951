import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AllFilters from "./AllFilters";
import Backdrop from "./Backdrop";
import FilterListIcon from "@material-ui/icons/FilterList";
import CurrentRefinements from "./CurrentRefinement";

const tagsDic = payload => ({
  "city.name": "Ciudad: " + payload,
  "neighborhood.name": "Barrio: " + payload,
  rooms: "Habitaciones: " + payload,
  bathrooms: "Baños: " + payload,
  parking_slots: "Parqueaderos: " + payload,
  "type.name": "Tipo:",
  "amenities.name": "Comodidades:"
});

/*function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}*/

export default function Index(props) {
  const classes = useStyles();
  const [menu, setMenu] = useState({ open: false, opt: "" });
  useEffect(() => {
    document.body.style.overflow = menu.open ? "hidden" : "unset";
  }, [menu]);

  function closeMenu() {
    setMenu({ open: false, opt: "" });
  }

  function toggleMenu() {
    setMenu({ open: !menu.open, opt: "all" });
  }

  return (
    <React.Fragment>
      <AppBar className={classes.absolute}>
        <CssBaseline />
        <Box className={classes.figure}>
          <img
            src={require("../../assets/images/logo.png")}
            alt={"Alt"}
            className={classes.imageContainer}
          />
        </Box>
        <Box className={classes.menu}>
          <ul className={classes.menuContainer}>
            <li
              className={clsx(
                classes.menuItem,
                menu.opt === "all" && menu.open && classes.selectedMenu
              )}
              id="all"
              onClick={toggleMenu}
            >
              <FilterListIcon color={"primary"} />
              <Typography className={classes.noEvent}>Filtros</Typography>
            </li>
            <div className={clsx(classes.latFilters)}>
              <CurrentRefinements
                // Optional parameters
                clearsQuery
                transformItems={items =>
                  items.map(el => {
                    return {
                      ...el,
                      label:
                        tagsDic(el.currentRefinement)[el.attribute] || el.label
                    };
                  })
                }
              />
            </div>
          </ul>
          <AllFilters
            selected={menu.opt}
            open={menu.open}
            closeMenu={closeMenu}
          />
        </Box>
      </AppBar>
      <Backdrop open={menu.open} closeMenu={closeMenu} />
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  warning: {
    color: "yellow"
  },
  table: {
    max: 650
  },
  figure: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main
  },
  imageContainer: {
    padding: "10px 0",
    height: "auto",
    width: 254
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },

  menuItem: {
    zIndex: 999,
    height: "30px",
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "3px 5px 3px 5px",
    color: theme.palette.primary.main,
    margin: "0 5px 0 5px"
  },

  latFilter: {
    zIndex: 999,
    height: "30px",
    display: "flex"
  },

  selectedMenu: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main
  },
  menuContainer: {
    paddingLeft: 0,
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  icon: {
    pointerEvents: "none",
    height: "15px"
  },
  iconSelected: {
    filter: "sepia(300%) saturate(300%) brightness(300%) hue-rotate(300deg)"
  },
  noEvent: {
    pointerEvents: "none"
  }
}));

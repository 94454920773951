import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CustomToggleRefinement from "../Algolia/ToggleRegfinement/Index";

function ExtraFilter(props) {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <ul className={classes.list}>
        <CustomToggleRefinement
          attribute={"balcony"}
          label={"Balcón/ Terraza"}
          // Optional parameters
          value={"true"}
        />
        <CustomToggleRefinement
          attribute={"service_room"}
          label={"Cuarto de Servicio"}
          // Optional parameters
          value={"true"}
        />
      </ul>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex"
  },
  list: {
    margin: 0,
    padding: "0 0 10px 0",
    width: "100%",
    color: theme.palette.primary.main
  },
  filter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0 0 10px"
  },
  listCont: {
    display: "flex"
  },
  dotImage: { width: 15, height: 15, alignSelf: "center", marginRight: 10 }
}));

export default ExtraFilter;

import { connectCurrentRefinements } from "react-instantsearch-dom";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const ClearRefinements = ({ items, refine, closeMenu }) => {
  const classes = useStyles();
  return (
    <Typography
      onClick={() => {
        refine(items);
        closeMenu();
      }}
      disabled={!items.length}
      className={classes.resetText}
    >
      Resetear
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  resetText: {
    display: "inline-block",
    color: theme.palette.secondary.main,
    marginLeft: "5px",
    cursor: "pointer"
  }
}));
export default connectCurrentRefinements(ClearRefinements);

import React, { Fragment } from "react";
import { connectStateResults } from "react-instantsearch-dom";
import Loader from "react-loader-spinner";
function Index(props) {
  const { searching } = props;
  return (
    <Fragment>
      {searching && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
            height: 150
          }}
        >
          <Loader
            type="Puff"
            color="#9FD1C7"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      )}
    </Fragment>
  );
}

export default connectStateResults(Index);

import React, { useEffect } from "react";
//import qs from "qs";
import WrapperAlgolia from "../../components/WrapperAlgolia";
import Toolbar from "@material-ui/core/Toolbar";
import { CssBaseline } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { useHistory } from "react-router-dom";
import Menu from "../../components/Menu";
import MainCard from "../../components/Cards/MainCard";
import Loading from "../../components/Algolia/Loading";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
ReactGA.initialize("UA-174979103-1");

export const searchClient = algoliasearch(
  "SW1E4YVFEB",
  "a17e1b87c29f3fde879e95c2d009b4d6"
);

function Home(props) {
  const history = useHistory();
  const classes = useStyles();
  const handleOpen = el => {
    history.push({
      pathname: "/",
      search: "?property=" + el.id
    });
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <InstantSearch indexName="properties" searchClient={searchClient}>
      <CssBaseline />
      <Helmet>
        <title>Martha Zuccardi Finca Raiz Barranquilla Colombia</title>
        <meta
          name="description"
          content="Inmuebles exclusivos en la ciudad de Barranquilla que no encuentras en otra parte."
        />

        <meta
          property="og:title"
          content="Martha Zuccardi Finca Raiz Barranquilla Colombia"
        />
        <meta
          property="og:description"
          content="Inmuebles exclusivos en la ciudad de Barranquilla que no encuentras en otra parte."
        />
        <meta
          property="og:image"
          itemProp="image"
          content="https://marthazuccardi.s3.amazonaws.com/logo/marthazuccardilogocuadrado.png"
        />
      </Helmet>
      <div className={classes.mainContainer}>
        <header>
          <Menu />
        </header>
        <Toolbar />
        <Loading />
        <WrapperAlgolia
          Item={({ hits }) => {
            return (
              <Grid container>
                {hits.map(el => {
                  return (
                    <Grid
                      item
                      container
                      sm={6}
                      md={6}
                      xs={12}
                      lg={3}
                      key={"Item" + el.id}
                    >
                      <MainCard
                        onClick={() => handleOpen(el)}
                        source={(el.media[0] || {}).path}
                        key={`${(el.type || {}).name} - ${
                          el.area_m2
                        }m2 - Estrato ${el.stratum}`}
                        bathrooms={el.bathrooms}
                        rooms={el.rooms}
                        neighborhood={(el.neighborhood || {}).name}
                        city={(el.city || {}).name}
                        name={`${(el.type || {}).name} - ${
                          el.area_m2
                        }m2 - Estrato ${el.stratum}`}
                        //stratum={`Estrato ${el.stratum}`}
                        renting={el.renting === "true"}
                        selling={el.selling === "true"}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            );
          }}
        />
      </div>
    </InstantSearch>
  );
}

const useStyles = makeStyles(theme => ({
  mainContent: {},
  mainContainer: {
    fontFamily: "Poppins-Regular",
    padding: "50px 0 0 0"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none"
  },
  whatsAppBottom: {
    display: "flex",
    padding: "20px 0",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FAFAFA",
    position: "sticky",
    bottom: 0
  },
  float: {
    marginLeft: 5
  }
}));

export default Home;

import React  from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";


const CheckHash = props => {
  const { onSelecting, label, selected } = props;
  const classes = useStyles({ selected });
  return (
    <ButtonBase>
      <div
        onClick={() => {
          onSelecting(props.value);
        }}
        className={clsx(classes.container, selected && classes.selected)}
      >
        <Typography className={classes.text}>{label}</Typography>
      </div>
    </ButtonBase>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    height: "43px",
    width: "54px",
    border: "1px solid",
    borderColor: theme.palette.gray.border,
    justifyContent: "center",
    alignItems: "center"
  },
  selected: {
    backgroundColor: theme.palette.primary.main
  },
  text: props => ({
    fontSize: 20,
    textAlign: "center",
    color: props.selected
      ? theme.palette.secondary.main
      : theme.palette.primary.main
  })
}));

export default CheckHash;

import React from "react";
import { connectToggleRefinement } from "react-instantsearch-dom";
import Switch from "@material-ui/core/Switch";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { SET_FILTERS } from "../../../redux/types";

const AntSwitch = withStyles(theme => ({
  root: {
    width: 32,
    height: 18,
    padding: 0,
    margin: theme.spacing(1)
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(14px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 14,
    height: 14,
    boxShadow: "none"
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}
}))(Switch);

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    currentRefinement,
    refine,
    label,
    value,
    count,
    attribute,
    source
  } = props;

  return (
    <li className={classes.filter}>
      <Box className={classes.listCont}>
        {source && <img src={source} className={classes.dotImage} />}
        <Typography className={classes.text}>
          {label + "  "}
          {!currentRefinement
            ? `(${count.unchecked || 0})`
            : `(${count.checked || 0})`}
        </Typography>
      </Box>
      <AntSwitch
        checked={currentRefinement}
        onChange={() => {
          refine(!currentRefinement);
          dispatch({ type: SET_FILTERS, payload: { [attribute]: !value } });
        }}
      />
    </li>
  );
}

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.gray.text
  },
  container: {
    width: "100%",
    color: theme.palette.primary.main
  },
  list: {
    margin: 0,
    padding: "0 0 10px 0",
    width: "100%"
  },
  filter: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px"
  },
  listCont: {
    display: "flex"
  },
  dotImage: { alignSelf: "center", marginRight: 10 }
}));

export default connectToggleRefinement(Index);

import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connectRefinementList } from "react-instantsearch-dom";

function TypeFilter(props) {
  const {
    items,
    refine,
    createURL
  } = props;
  const classes = useStyles();
  const GreenCheckbox = withStyles({
    root: {
      color: "#9FD1C7",
      "&$checked": {
        color: "#9FD1C7"
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);

  return (
    <Grid container className={classes.container}>
      <ul className={classes.list}>
        {items.map((item, index) => (
          <li key={`type-${item.label}-${index}`} className={classes.item}>
            <FormControlLabel
              checked={item.isRefined}
              onChange={() => {
                createURL(item.value);
                refine(item.value);
              }}
              control={<GreenCheckbox name={`type-${index}`} />}
              label={item.label}
            />
          </li>
        ))}
      </ul>
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  container: {},
  list: {
    paddingLeft: "10px",
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.gray.text,
    justifyContent: "space-between"
  },
  item: {
    width: "100%"
  }
}));

export default connectRefinementList(TypeFilter);

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { getCroppedPicture, getFullPicture } from "../../../modules/S3";
import Dimensions from "react-dimensions";
import Fade from "@material-ui/core/Fade";

function Index(props) {
  const {
    name,
    renting,
    selling,
    neighborhood,
    city,
    bathrooms,
    rooms,
    source,
    containerWidth,
    stratum
  } = props;

  const [relation, setRelation] = useState(1);
  const [loaded, setLoaded] = useState(false);

  function getMeta(url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = function() {
      callback(this.width, this.height);
    };
  }
  const classes = useStyles({
    source: getFullPicture(source),
    height: Math.round(containerWidth / relation)
  });
  useEffect(() => {
    getMeta(getCroppedPicture(source, "cover", { width: 1000 }), function(
      width,
      height
    ) {
      setRelation(width / height);

      setLoaded(true);
    });
  }, []);
  return (
    <Fade in={loaded}>
      <div className={classes.container} onClick={props.onClick}>
        <Box className={classes.hSpace}>
          <Box>
            <Box className={classes.row}>
              {renting && (
                <Typography className={classes.tag}>Arriendo</Typography>
              )}
              {selling && (
                <Typography className={classes.tag}>Venta</Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Typography className={classes.text}></Typography>
          </Box>
        </Box>
        <Box className={classes.hSpace}>
          <Box>
            <Typography className={classes.text}>{name}</Typography>
            <Typography className={classes.text}>{stratum}</Typography>
            <Typography
              className={classes.text}
            >{`${city}, ${neighborhood}`}</Typography>
          </Box>
          <Box className={classes.rooms}>
            <Box className={classes.roomSection}>
              <Typography
                className={clsx(classes.text)}
              >{`${rooms}`}</Typography>
              <Typography className={clsx(classes.text)}>Cuartos</Typography>
            </Box>
            <div className={classes.separator} />
            <Box className={classes.roomSection}>
              <Typography className={clsx(classes.text)}>
                {`${bathrooms}`}
              </Typography>
              <Typography className={clsx(classes.text)}>Baños</Typography>
            </Box>
          </Box>
        </Box>
      </div>
    </Fade>
  );
}

const useStyles = makeStyles(theme => ({
  hSpace: {
    display: "flex",
    justifyContent: "space-between"
  },
  container: props => ({
    cursor: "pointer",
    fontWeight: "600",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: "300px",
    height: `${props.height}px`,
    backgroundSize: "cover",
    backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,0) 77%,rgba(0,0,0,.8) 100%),url(${props.source})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }),
  "@media (min-width: 1024px)": {
    container: props => ({
      height: "400px",
      backgroundPosition: "center"
    })
  },
  row: {
    display: "flex"
  },
  text: {
    color: theme.palette.secondary.main,
    fontWeight: "500"
  },
  warning: {
    color: "yellow"
  },
  table: {
    max: 650
  },
  figure: {
    paddingTop: 10,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main
  },
  imageContainer: {
    height: 36,
    width: 216
  },
  menu: {
    backgroundColor: theme.palette.secondary.main
  },
  tag: {
    margin: "0 5px 0 5px",
    padding: "0 10px",
    color: theme.palette.secondary.main,
    border: "1px solid",
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRadius: "11px"
  },
  rooms: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  roomSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  separator: {
    width: "2px",
    height: "100%",
    backgroundColor: theme.palette.secondary.main,
    margin: "0 5px 0 5px"
  }
}));

export default Dimensions()(Index);

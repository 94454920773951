import React from "react";
import {
  connectInfiniteHits,
} from "react-instantsearch-dom";
class InfiniteHits extends React.PureComponent {
  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits, Item } = this.props;

    return (
      <div className="ais-InfiniteHits">
        <Item hits={hits} />
        <div
          className="ais-InfiniteHits-sentinel"
          ref={c => (this.sentinel = c)}
        />
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);

import { encode } from "base-64";
export const S3CREDENTIALS = {
  bucket: "marthazuccardi",
  base: "https://d2hryuevwfj4u9.cloudfront.net/",
  bucketUrl: "https://appartaapp.s3.amazonaws.com/"
};

export const getFullPicture = (path, fit) => {
  const json = JSON.stringify({
    bucket: S3CREDENTIALS.bucket,
    key: path,
    edits: {
      resize: {
        fit,
        background: {
          r: 255,
          g: 255,
          b: 255,
          alpha: 1
        }
      }
    }
  });
  const base64 = encode(json);
  const url = `${S3CREDENTIALS.base}${base64}`;
  if (!path) {
    return " ";
  }
  return url;
};

export const getCroppedPicture = (path, fit, { width, height, background }) => {
  const json = JSON.stringify({
    bucket: S3CREDENTIALS.bucket,
    key: path,
    edits: {
      resize: {
        width,
        height,
        fit,
        background: background || {
          r: 255,
          g: 255,
          b: 255,
          alpha: 1
        }
      }
    }
  });

  const base64 = encode(json);
  const url = `${S3CREDENTIALS.base}${base64}`;
  if (!path) {
    return " ";
  }
  return url;
};

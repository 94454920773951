import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function Backdrop(props) {
  const { closeMenu } = props;
  const classes = useStyles(props);
  return <div className={classes.container} onClick={closeMenu} />;
}

const useStyles = makeStyles(theme => ({
  container: ({ open }) => ({
    backgroundColor: "rgba(0,0,0,.3)",
    position: "absolute",
    display: !open && "none",
    width: "100%",
    height: "100%"
  })
}));
export default Backdrop;

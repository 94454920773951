import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import Select from "@material-ui/core/Select";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { connectMenu } from "react-instantsearch-dom";

const BootstrapInput = withStyles(theme => ({
  root: {
    borderWidth: 0,

    "label + &": {}
  },
  input: {
    borderWidth: 0,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"])
  }
}))(InputBase);

function Index(props) {
  const classes = useStyles();
  const [data, setData] = useState(" ");
  const { items, refine, placeholder } = props;

  const handleChange = event => {
    refine(event.target.value);
    setData(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={data}
        onChange={handleChange}
        input={
          <BootstrapInput
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start">
                <RoomOutlinedIcon color="primary" />
              </InputAdornment>
            }
          />
        }
      >
        <MenuItem value=" " disabled>
          {placeholder}
        </MenuItem>
        {items.map(el => {
          return (
            <MenuItem value={el.label} key={"data" + el.label}>
              {el.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    color: theme.palette.gray.text,
    flex: 1,
    boxShadow: "0px 10px 16px 0px rgba(0,0,0,0.16)",
    margin: theme.spacing(1),
    minWidth: 120,
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    padding: "5px 2px"
  },
  selectEmpty: {}
}));

export default connectMenu(Index);

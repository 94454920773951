import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connectNumericMenu } from "react-instantsearch-dom";

const CheckButtonGroup = props => {
  const { Radio, items, refine, createURL } = props;
  const classes = useStyles();
  const _renderItems = () =>
    items.map((q, index) => {
      return (
        <div key={"cheked" + index}>
          {React.cloneElement(Radio, {
            key: "select-" + q.id,
            ...q,
            value: `${q.value}`,
            onSelecting: () => {
              refine(q.value);
              createURL(q.value);
            },
            selected: q.isRefined
          })}
        </div>
      );
    });

  return <div className={classes.container}>{_renderItems()}</div>;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  }
}));

export default connectNumericMenu(CheckButtonGroup);

import React from "react";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import SelectMenu from "../Algolia/SelecMenu";

function SelectFilters(props) {
  return (
    <Fade in={true}>
      <Grid container>
        <SelectMenu
          attribute="city.name"
          placeholder={"Ciudad"}
          label={"Ciudad"}
        />
        <SelectMenu
          attribute="neighborhood.name"
          placeholder={"Barrio"}
          label={"Barrio"}
        />
      </Grid>
    </Fade>
  );
}

export default SelectFilters;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import numeral from "numeral";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core";
import _ from "lodash";

const GreenCheckbox = withStyles({
  root: {
    color: "#9FD1C7",
    "&$checked": {
      color: "#9FD1C7"
    },
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

function Index(props) {
  const classes = useStyles();
  const { selected } = props;
  return (
    <div>
      <div className={classes.types}>
        {selected.sale_price > 0 && (
          <div className={classes.itemType}>
            <Typography className={classes.title}>Venta</Typography>
            <Typography className={classes.lowText}>
              ${numeral(selected.sale_price).format("0,000")}
            </Typography>
          </div>
        )}
        {selected.rent_price > 0 && (
          <div className={classes.itemType}>
            <Typography className={classes.title}>Arriendo</Typography>
            <Typography className={classes.lowText}>
              ${numeral(selected.rent_price).format("0,000")}
            </Typography>
          </div>
        )}
        {selected.management_price > 0 && (
          <div className={classes.itemType}>
            <Typography className={classes.title}>Administración</Typography>
            <Typography className={classes.lowText}>
              ${numeral(selected.management_price).format("0,000")}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.features}>
        <Typography className={classes.title}>
          Caracteristicas Adicionales:
        </Typography>
        {selected.parking_slots > 0 && (
          <Typography
            className={clsx(
              classes.lowText,
              classes.spacing,
              classes.capitalize
            )}
          >
            Garajes: {selected.parking_slots}
          </Typography>
        )}
        {selected.balcony && (
          <div>
            <Typography
              className={clsx(
                classes.lowText,
                classes.spacing,
                classes.capitalize
              )}
            >
              Balcón/Terraza
              {selected.balcony_area_m2 > 0 && `: $${selected.balcony_area_m2}`}
            </Typography>
          </div>
        )}
        {selected.use_type && (
          <Typography
            className={clsx(
              classes.lowText,
              classes.spacing,
              classes.capitalize
            )}
          >
            Tipo: {`${selected.use_type}`}
          </Typography>
        )}
        {selected.service_room && (
          <Typography className={clsx(classes.lowText, classes.spacing)}>
            Incluye cuarto de servicio
          </Typography>
        )}
      </div>
      <div className={classes.amenities}>
        <Typography className={classes.title}>Amenidades:</Typography>
        <div>
          <ul className={classes.list}>
            {_.sortBy(selected.amenities || [], ["name"]).map((item, index) => (
              <li key={`type-${item.id}-${index}`} className={classes.item}>
                <FormControlLabel
                  checked={true}
                  control={<GreenCheckbox name={`type-${index}`} />}
                  label={item.name}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main
  },
  lowText: {
    color: theme.palette.gray.text
  },
  capitalize: {
    textTransform: "capitalize"
  },
  types: {
    marginTop: 20,
    display: "flex",
    justifyContent: "space-around"
  },
  itemType: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  features: {
    marginTop: "20px",
    marginLeft: "10px"
  },
  spacing: {
    margin: "5px 0"
  },
  amenities: {
    marginTop: "20px",
    marginLeft: "10px"
  },
  list: {
    paddingLeft: "0px",
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    color: theme.palette.gray.text,
    justifyContent: "space-between"
  },
  item: {
    width: "100%",
    color: theme.palette.gray.text
  },
  whatsAppBottom: {
    padding: "20px 0",
    display: "flex",
    backgroundColor: "#FAFAFA",
    justifyContent: "space-around",
    alignItems: "center"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none"
  },
  float: {
    marginLeft: 5
  }
}));
export default Index;
